import contemporaryRaw from '@/templates/contemporary.hbs?raw'
import defaultRaw from '@/templates/default.hbs?raw'
import centeredRaw from '@/templates/centered.hbs?raw'
import emailRaw from '@/templates/email.hbs?raw'
import footerRaw from '@/templates/footer.hbs?raw'
import headerRaw from '@/templates/header.hbs?raw'
import karmaRaw from '@/templates/karma.hbs?raw'
import testRaw from '@/templates/test.hbs?raw'
import statementRaw from '@/templates/statement.hbs?raw'
import portraitsArtistConfirmationRaw from '@/templates/portraits_artist_confirmation.hbs?raw'
import portraitsConfirmationRaw from '@/templates/portraits_confirmation.hbs?raw'
import portraitsDepositRaw from '@/templates/portraits_deposit.hbs?raw'
import portraitsTravelInvoiceRaw from '@/templates/portraits_travel_invoice.hbs?raw'
import portraitsRaw from '@/templates/portraits.hbs?raw'
import praxisRaw from '@/templates/praxis.hbs?raw'
import praxisHeaderRaw from '@/templates/praxis_header.hbs?raw'
import commissionOnlyRaw from '@/templates/commission_only.hbs?raw'
import southernGuildQuoteRaw from '@/templates/southern_guild_quote.hbs?raw'
import southernGuildQuoteUSRaw from '@/templates/southern_guild_quote_US.hbs?raw'
import simpleRaw from '@/templates/simple.hbs?raw'
import streamlinedRaw from '@/templates/streamlined.hbs?raw'
import ruttkowskiNYRaw from '@/templates/ruttkowskiNY.hbs?raw'
import ruttkowskiGerRaw from '@/templates/ruttkowskiGer.hbs?raw'
import ruttkowskiParisRaw from '@/templates/ruttkowskiParis.hbs?raw'
import ruttkowskiHeaderRaw from '@/templates/ruttkowski_header.hbs?raw'


export default (fileName: string) => {
  switch (fileName) {
    case 'contemporary':
      return contemporaryRaw
    case 'default':
      return defaultRaw
    case 'centered':
      return centeredRaw
    case 'email':
      return emailRaw
    case 'footer':
      return footerRaw
    case 'header':
      return headerRaw
    case 'karma':
      return karmaRaw
    case 'test':
      return testRaw
    case 'statement':
      return statementRaw
    case 'portraits_artist_confirmation':
      return portraitsArtistConfirmationRaw
    case 'portraits_confirmation':
      return portraitsConfirmationRaw
    case 'portraits_deposit':
      return portraitsDepositRaw
    case 'portraits_travel_invoice':
      return portraitsTravelInvoiceRaw
    case 'portraits':
      return portraitsRaw
    case 'praxis':
      return praxisRaw
    case 'praxis_header':
      return praxisHeaderRaw
    case 'commission_only':
        return commissionOnlyRaw
    case 'southern_guild_quote':
        return southernGuildQuoteRaw
    case 'southern_guild_quote_US':
      return southernGuildQuoteUSRaw
    case 'simple':
      return simpleRaw
    case 'streamlined':
      return streamlinedRaw
    case 'ruttkowskiNY':
      return ruttkowskiNYRaw
    case 'ruttkowskiGer':
      return ruttkowskiGerRaw
    case 'ruttkowskiParis':
        return ruttkowskiParisRaw
    case 'ruttkowskiHeader':
        return ruttkowskiHeaderRaw
    default:
      throw new Error(`Template file ${fileName} not found`)
  }
}
